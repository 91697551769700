import { ClientLabel } from 'labels/client'

import {
	EXPANDED_POLICY_LINK_TESTID,
	PRIVACY_POLICY_PAGE_PATHNAME,
} from '../../../../constants/privacyPolicy'

import styles from './ClientExpandedPolicy.module.scss'
import text from 'fukku/styles/texts.module.scss'

type ExpandedPolicyProps = {
	readonly title: string
	readonly body: string
	readonly id: string
}

export function ClientExpandedPolicy({ title, body, id }: ExpandedPolicyProps) {
	const placeholderValues = {
		PrivacyPolicy: PRIVACY_POLICY_PAGE_PATHNAME,
	}
	const linkProps = {
		'data-testid': `${id}.${EXPANDED_POLICY_LINK_TESTID}`,
		small: true,
	}

	return (
		<>
			<p className={`${text.titleS} ${styles.policyTitle}`}>
				<ClientLabel>{title}</ClientLabel>
			</p>
			<p className={text.bodyS}>
				<ClientLabel
					placeholderValues={placeholderValues}
					linkProps={linkProps}
					boldProps={{ className: text.bodySRegular }}
				>
					{body}
				</ClientLabel>
			</p>
		</>
	)
}

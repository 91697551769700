'use client'

import { ClientLabel } from 'labels/client'

import {
	PRIVACY_POLICY_PAGE_PATHNAME,
	TERMS_CONDITIONS_PAGE_PATHNAME,
} from '../../../constants/privacyPolicy'
import { SimplePolicies } from '../SimplePolicies'

export function ClientSimplePolicies({
	className,
	text,
}: {
	readonly className?: string
	readonly text: string
}) {
	const placeholderValues = {
		termsAndConditions: TERMS_CONDITIONS_PAGE_PATHNAME,
		PrivacyPolicy: PRIVACY_POLICY_PAGE_PATHNAME,
	}

	return (
		<SimplePolicies className={className}>
			<ClientLabel placeholderValues={placeholderValues}>{text}</ClientLabel>
		</SimplePolicies>
	)
}
